import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { ISetPushNotify, ISize, ISetHideStars, IUserInfoForUpdate } from '../../api/types';
import { getTextError } from '../utils';
import { updateOnboardStep } from './slice';
import { createServiceNotice } from '../notifications/slice';
import { Environment, ErrorMessages, noticeCreator, SuccessMessages } from '../../utils';
import { updateUserVersion } from '../../utils/updateUserVersion';

export const getUserInfoThunk = createAsyncThunk('user/getUserInfo', async (_, { rejectWithValue, dispatch }) => {
  try {
    const platform = await window?.getPlatform();

    const user = (await api.getMyInfo(platform)).data.data;

    updateUserVersion(user);

    return user;
  } catch (error) {
    const textError = getTextError(error);

    dispatch(
      createServiceNotice({
        notice: noticeCreator(textError, 'error'),
        otherInfo: { error, pathname: 'user/getUserInfo', forEnvironment: Environment.development },
      })
    );

    return rejectWithValue(textError);
  }
});

export const getUserAvatarThunk = createAsyncThunk(
  'user/getUserAvatar',
  async ({ avatar, size }: { avatar: string; size: ISize }, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.getAvatar({ identifier: avatar, size });

      return response.data ? URL.createObjectURL(response.data) : '';
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'user/getUserAvatar', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const updateUserInfoThunk = createAsyncThunk(
  'user/updateUserInfo',
  async (userInfo: IUserInfoForUpdate, { rejectWithValue, dispatch }) => {
    try {
      return (await api.updateUserInfo(userInfo)).data.data;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'user/updateUserInfo', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const updatePushNotifyThunk = createAsyncThunk(
  'user/updatePushNotifyThunk',
  async (pushNotify: ISetPushNotify, { rejectWithValue, dispatch }) => {
    try {
      return (await api.setPushNotify(pushNotify)).data.data;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'user/updatePushNotifyThunk', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const updateHideStarsThunk = createAsyncThunk(
  'user/updateHideStars',
  async (hide_stars: ISetHideStars, { rejectWithValue, dispatch }) => {
    try {
      return (await api.setHideStars(hide_stars)).data.data;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'user/updateHideStars', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const updateOnboardStepThunk = createAsyncThunk(
  'user/updateOnboardStep',
  async (onboardStep: number, { rejectWithValue, dispatch }) => {
    try {
      dispatch(updateOnboardStep(onboardStep));

      api.updateOnboardStep({ onboard_step: onboardStep });
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'user/updateOnboardStep', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const updateAutopayThunk = createAsyncThunk(
  'user/updateAutopay',
  async (autopay: boolean, { rejectWithValue, dispatch }) => {
    try {
      const response = (await api.updateAutopay({ autopay })).data.data;

      dispatch(
        createServiceNotice({
          notice: noticeCreator(response ? SuccessMessages.autopayOn : SuccessMessages.autopayOff, 'success', 1000),
          otherInfo: { pathname: 'user/updateAutopay', forEnvironment: Environment.production },
        })
      );

      return response;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(ErrorMessages.defaultTextError, 'error'),
          otherInfo: { error, pathname: 'user/updateAutopay', forEnvironment: Environment.production },
        })
      );

      return rejectWithValue(textError);
    }
  }
);
