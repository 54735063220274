import api from '../api';
import { IUser } from '../api/types';

export const updateUserVersion = async (user: IUser) => {
  if (!user) return;

  const currentVersionName = await window?.getVersionName();

  if (currentVersionName !== user?.ver) {
    api.setVersion({ ver: currentVersionName });
  }
};
