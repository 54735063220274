import { Divider, Popover, Stack } from '@mui/material';
import { ProductCard, Typography } from 'front-package-ui-kit';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IWorkoutDetailse } from '../../../../api/types';
import defaultCover from '../../../../assets/image/defaultCover.png';
import { ImageCover } from '../../../../components';
import { openPaySubscribeBottomSheet } from '../../../../components/organisms/PaySubscribeBottomSheet';
import { useBottomSheet, useGroups, useOnFavoritesClickHandler } from '../../../../hooks';
import {
  COURSE_TYPE_NAMES,
  CourseOrWorkoutTypes,
  CourseTypes,
  ImagePaths,
  PRODUCT_CARD_IMAGE_SIZES,
  Paths,
  ResizeType,
  getCourseLevel,
  getCourseStatuses,
  withAnalytic,
} from '../../../../utils';

const { videoDetails, profile } = Paths;

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  workout: IWorkoutDetailse;
  type?: 'Menu' | 'Favorite';
  onDelete?: () => void;
}

const SIZE = 'S';

const WorkoutCard: FC<IProps> = ({
  workout: { image, id, is_public: isPublicWorkout, title, favorite, calories, inventory, duration, course },
  type = 'Favorite',
  onDelete,
  onClick,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { course_type: courseType } = course;

  const { onOpenBottomSheet } = useBottomSheet();

  const navigate = useNavigate();

  // const { intensityDictionary } = useDictionaries();

  const { isAvailable: isCourseAvailable } = getCourseStatuses(course);

  const isOpenTraining = isCourseAvailable || isPublicWorkout;

  const {
    groupsByType: {
      new: [novelty],
    },
  } = useGroups();

  const isCourseNew = course.groups.includes(+novelty?.id);

  const onClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (onClick) {
      onClick(e);
    }

    if (isOpenTraining) {
      //задержка установлена для ios
      setTimeout(() => {
        navigate(`${profile}${videoDetails}/${id}`);
      }, 100);

      return;
    }

    onOpenBottomSheet(openPaySubscribeBottomSheet);
  };

  const { onFavoritesClickHandler, isFavorite } = useOnFavoritesClickHandler(
    favorite,
    id,
    CourseOrWorkoutTypes.workout
  );

  const caloriesInfo = calories ? `${calories} ккал` : '';

  const inventoryInfo = inventory && inventory !== 'без инвентаря' ? 'инвентарь' : 'без инвентаря';

  // const intensityInfo = intensity && intensityDictionary[intensity] ? intensityDictionary[intensity] : '';

  const getBottomIconType = (): 'Videocamera' | 'Play' | undefined => {
    if (courseType === CourseTypes.complex) return 'Play';

    if (courseType === CourseTypes.multiple || courseType === CourseTypes.single) return 'Videocamera';
  };

  const getSubTitle = () => {
    if (courseType === CourseTypes.multiple) return course.name;
  };

  const getBottomText = () => {
    return `${duration} мин`;
  };

  const getIsNovelty = () => {
    if (courseType === CourseTypes.complex || courseType === CourseTypes.single) return isCourseNew;
  };

  const level = getCourseLevel(course?.levels);

  const getRowInfo = () => {
    if (courseType === CourseTypes.multiple) return [caloriesInfo, inventoryInfo, level?.title ?? ''];

    if (courseType === CourseTypes.single || courseType === CourseTypes.complex)
      return [COURSE_TYPE_NAMES?.[courseType], inventoryInfo, level?.title ?? ''];
  };

  const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);

    setOpen(true);
  };

  const closePopover = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const onDeleteHandler = () => {
    onDelete?.();
    closePopover();
  };

  const toggleFavorite = () => {
    onFavoritesClickHandler();
    closePopover();
  };

  return (
    <>
      <ProductCard
        size={SIZE}
        onClick={onClickHandler}
        bottomIconType={getBottomIconType()}
        rowInfo={{ options: getRowInfo() }}
        title={title}
        subTitle={getSubTitle()}
        bottomText={getBottomText()}
        novelty={getIsNovelty()}
        lock={!isOpenTraining}
        topRightIconOptions={
          type === 'Favorite'
            ? { type: 'Favorite', onClick: onFavoritesClickHandler, isActive: isFavorite }
            : { type: 'Menu', onClick: openPopover }
        }
        cover={
          <ImageCover
            img={{
              baseApiService: 'VIDEO',
              imagePath: image ? ImagePaths.workoutImage : ImagePaths.courseCover,
              name: image ? image : course.cover,
              width: PRODUCT_CARD_IMAGE_SIZES[SIZE].width,
              height: PRODUCT_CARD_IMAGE_SIZES[SIZE].height,
              resize: ResizeType.resizeBothCenter,
            }}
            defaultImg={defaultCover}
          />
        }
        {...props}
      />

      {type === 'Menu' && (
        <Popover
          open={open}
          style={{ zIndex: 99999 }}
          anchorEl={anchorEl}
          onClose={closePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Stack px="14px">
            <button
              style={{
                padding: '14px 0',
                marginRight: '14px',
              }}
              onClick={toggleFavorite}
            >
              <Typography variant="text5" textAlign="left">
                {isFavorite ? 'Убрать из избранного' : 'Добавить в избранное'}
              </Typography>
            </button>

            <Divider />

            <button
              style={{
                padding: '14px 0',
                marginRight: '14px',
              }}
              onClick={onDeleteHandler}
            >
              <Typography variant="text5" textAlign="left">
                Удалить из активности
              </Typography>
            </button>
          </Stack>
        </Popover>
      )}
    </>
  );
};

export default withAnalytic(WorkoutCard);
