import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TImages } from '../../api/types';

interface IImages {
  images: TImages;
}

const initialState: IImages = {
  images: {},
};

export const imagesSlice = createSlice({
  name: 'images',
  initialState,
  reducers: {
    setImage(state, { payload: { name, imageUrl } }: PayloadAction<{ name: string; imageUrl: string }>) {
      state.images[name] = imageUrl;
    },
  },
  extraReducers: {},
});

export const { setImage } = imagesSlice.actions;

export default imagesSlice.reducer;
