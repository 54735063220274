import React, { ReactNode } from 'react';
import s from './styles.module.scss';
import { IUserActivity, IWorkout, IWorkoutDetailse } from '../../../api/types';
import { Stack, StackProps } from '@mui/material';
import { SkeletonWorkoutCard } from '../..';

export const TEST_ID = 'WorkoutList';

const DEFAULT_SKELETONS = [1, 2, 3, 4, 5, 6, 7];

interface IProps<T> extends StackProps {
  options: T[];
  renderCard: (params: T) => ReactNode;
  loading?: boolean;
  skeletons?: number;
}

const WorkoutList = <T extends IWorkout | IWorkoutDetailse | IUserActivity>({
  options,
  renderCard,
  loading,
  skeletons,
  ...props
}: IProps<T>) => {
  const numberOfSkeletons =
    skeletons === undefined
      ? DEFAULT_SKELETONS
      : skeletons < DEFAULT_SKELETONS.length
      ? new Array(skeletons).fill(null)
      : DEFAULT_SKELETONS;

  return (
    <Stack component="section" rowGap="12px" className={s.workoutList} data-testid={TEST_ID} {...props}>
      {!loading && options.map((workout) => renderCard(workout))}

      {loading && numberOfSkeletons.map((_, i) => <SkeletonWorkoutCard size="S" key={i} />)}
    </Stack>
  );
};

export default WorkoutList;
