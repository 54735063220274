import React, { FC, memo, useEffect, useRef } from 'react';
import { getImageByName } from '../../../pages/Stories/model/selectors';
import cn from 'classnames';
import s from './styles.module.scss';
import { IImagePathProps } from '../../../models';
import { getImagePath, observeImage } from '../../../utils';
import { useAppSelector } from '../../../hooks';

export const TEST_ID = 'ImageCover';

export interface ImageCoverProps
  extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  img?: IImagePathProps;
  defaultImg?: string;
  maxZIndexPreloader?: boolean;
  fit?: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
  onLoad?: () => void;
}

const ImageCover: FC<ImageCoverProps> = ({ img, defaultImg, maxZIndexPreloader, fit = 'cover', onLoad, ...props }) => {
  const ref = useRef<HTMLImageElement | null>(null);

  const nameForStore = img ? `${img.name}/${img.width}/${img.height}` : '';

  const imageFromStore = useAppSelector(getImageByName(nameForStore));

  const storedImage = imageFromStore ? imageFromStore : defaultImg;

  const imgUrl = img ? getImagePath(img) : '';

  useEffect(() => {
    if (!ref.current || !img) return;

    observeImage(ref.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return (
    <div className={s.imageCover}>
      <img
        ref={ref}
        data-testid={TEST_ID}
        className={s.img}
        src={storedImage}
        style={{ objectFit: fit }}
        alt=""
        data-src={imgUrl}
        data-nameforstore={nameForStore}
        {...props}
      />
    </div>
  );
};

export const ImageCoverMemo = memo(ImageCover);
