import { useEffect, useMemo, useState, memo } from 'react';
import { Container, Stack } from '@mui/material';
import s from './styles.module.scss';
import { useAppDispatch, useAuthRedirect, useParseWebView, useUser } from '../../hooks';
import {
  OnboardingSteps,
  Paths,
  ValuesOfConstants,
  getIsNeedOnboarding,
  getIsValue,
  setInitMetrika,
} from '../../utils';
import { CssPlatformContainer, NavBar } from '../../components';
import { getUserInfoThunk, updateOnboardStepThunk } from '../../redux/user/thunks';
import { Typography } from 'front-package-ui-kit';
import { useStep } from '../LoginRegister/hooks/useStep';
import { useEffectOnce } from 'usehooks-ts';
import { getParametersThunk } from '../../redux/parameters/thunks';
import { useNavigate } from 'react-router-dom';
import {
  AboutSelf,
  ChooseTarget,
  CreatingProgram,
  DefineLevel,
  SetDateOfBirth,
  SetHeight,
  SetWeight,
} from './components';
import { getLevelsDictionaryThunk } from '../../redux/dictionaries/thunks';
import { getGroupThunk } from '../../redux/groups/thunk';

const requiredStepDictionary = {
  [OnboardingSteps.aboutSelf]: true,
  [OnboardingSteps.setDateOfBirth]: false,
  [OnboardingSteps.setWeight]: false,
  [OnboardingSteps.setHeight]: false,
  [OnboardingSteps.chooseTarget]: true,
  [OnboardingSteps.defineLevel]: true,
  [OnboardingSteps.creatingProgram]: true,
} as const;

const { main } = Paths;

export const TEST_ID = 'Onboarding';

const Onboarding = () => {
  //редирект авторизованного пользователя обратно в приложение
  useAuthRedirect();

  const { currentStep, nextStepHandler, goBackHandler } = useStep();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { user } = useUser();

  const [userLevel, setUserLevel] = useState<string | null>(null);

  const getBeginStep = () => {
    const arrOfSteps = Object.values(OnboardingSteps);

    if (user?.onboard_step === undefined) {
      return arrOfSteps.splice(0, 1);
    } else {
      return arrOfSteps.splice(0, user.onboard_step + 1);
    }
  };

  useEffectOnce(() => {
    (async () => {
      const isNeedOnboarding = getIsNeedOnboarding();

      if (isNeedOnboarding === false) navigate(main);
    })();
  });

  useEffectOnce(() => {
    if (!user) dispatch(getUserInfoThunk());

    dispatch(getParametersThunk());
  });

  useEffect(() => {
    if (user && !currentStep) {
      const steps = getBeginStep();

      steps.forEach((step) => nextStepHandler(step));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, currentStep]);

  const isNavBarHidden = !getIsValue(currentStep, OnboardingSteps.creatingProgram);

  const stepNumber = useMemo(() => {
    if (!currentStep) return 0;

    const indexOfS = Object.values(OnboardingSteps).indexOf(currentStep as unknown as OnboardingSteps);

    return indexOfS + 1;
  }, [currentStep]);

  const numberOfSteps = useMemo(() => {
    return Object.values(OnboardingSteps).length - 1;
  }, []);

  const handleStep = (step?: OnboardingSteps) => () => {
    if (step) nextStepHandler(step);

    if (user?.onboard_step !== undefined && user.onboard_step < stepNumber)
      dispatch(updateOnboardStepThunk(stepNumber));
  };

  const isStepCounter = stepNumber <= numberOfSteps;

  const isStepRequired = requiredStepDictionary[currentStep as OnboardingSteps];

  const nextStep = Object.values(OnboardingSteps)[stepNumber];

  const listForCheckBox = useParseWebView([
    ValuesOfConstants.onboardingLevel1,
    ValuesOfConstants.onboardingLevel2,
    ValuesOfConstants.onboardingLevel3,
  ]);

  useEffect(() => {
    if (currentStep === OnboardingSteps.creatingProgram) {
      if (user) setInitMetrika(user);
    }
  }, [currentStep, user]);

  useEffectOnce(() => {
    dispatch(getLevelsDictionaryThunk());

    dispatch(getGroupThunk());
  });

  return (
    <Container disableGutters maxWidth="xs" className={s.onboarding} data-testid={TEST_ID}>
      <CssPlatformContainer>
        {Boolean(currentStep) && (
          <Stack className={s.onboardingContent}>
            {currentStep && isNavBarHidden && (
              <NavBar
                onBackClick={goBackHandler}
                justifyContent="flex-end"
                marginBottom="24px"
                goBackButton={currentStep !== OnboardingSteps.aboutSelf}
                goBackButtonProps={{ white: true }}
              >
                {!isStepRequired && (
                  <Typography
                    onClick={handleStep(nextStep)}
                    variant="text4"
                    color="white"
                    opacity={0.5}
                    fontWeight={500}
                  >
                    Пропустить
                  </Typography>
                )}
              </NavBar>
            )}

            {isStepCounter && (
              <Stack direction="row" padding="0 20px" marginBottom="8px">
                <Typography variant="text6" color="lime" textTransform="uppercase">
                  {stepNumber}
                </Typography>

                <Typography variant="text6" color="white" textTransform="uppercase">
                  {`/${numberOfSteps}`}
                </Typography>
              </Stack>
            )}

            {currentStep === OnboardingSteps.aboutSelf && (
              <AboutSelf
                handleStep={handleStep(OnboardingSteps.setDateOfBirth)}
                stepNumber={stepNumber}
                numberOfSteps={numberOfSteps}
              />
            )}

            {currentStep === OnboardingSteps.setDateOfBirth && (
              <SetDateOfBirth
                handleStep={handleStep(OnboardingSteps.setWeight)}
                stepNumber={stepNumber}
                numberOfSteps={numberOfSteps}
              />
            )}

            {currentStep === OnboardingSteps.setWeight && (
              <SetWeight
                handleStep={handleStep(OnboardingSteps.setHeight)}
                stepNumber={stepNumber}
                numberOfSteps={numberOfSteps}
              />
            )}

            {currentStep === OnboardingSteps.setHeight && (
              <SetHeight
                stepNumber={stepNumber}
                numberOfSteps={numberOfSteps}
                handleStep={handleStep(OnboardingSteps.chooseTarget)}
              />
            )}

            {currentStep === OnboardingSteps.chooseTarget && (
              <ChooseTarget
                handleStep={handleStep(OnboardingSteps.defineLevel)}
                stepNumber={stepNumber}
                numberOfSteps={numberOfSteps}
              />
            )}

            {currentStep === OnboardingSteps.defineLevel && (
              <DefineLevel
                userLevel={userLevel}
                setUserLevel={setUserLevel}
                handleStep={handleStep(OnboardingSteps.creatingProgram)}
                stepNumber={stepNumber}
                numberOfSteps={numberOfSteps}
                listForCheckBox={listForCheckBox}
              />
            )}

            {currentStep === OnboardingSteps.creatingProgram && <CreatingProgram handleStep={handleStep()} />}
          </Stack>
        )}
      </CssPlatformContainer>
    </Container>
  );
};

export default memo(Onboarding);
