import { setPrefilledFilterName, setPrefilledFilterValue } from '../redux/filters/slice';
import { useAppDispatch } from './redux';
import { FilterNames } from '../utils';

const usePrefilledFilter = () => {
  const dispatch = useAppDispatch();

  return (name: FilterNames | null, value: string) => {
    dispatch(setPrefilledFilterName(name));
    dispatch(setPrefilledFilterValue(value));
  };
};

export default usePrefilledFilter;
