/**
 * Splits the input text into an array of strings and URLs.
 *
 * @param {string} text - The input text to be split.
 * @return {(string | null)[]} An array of strings and URLs.
 */
export const splitTextByLink = (text: string): (string | null)[] => {
  // Регулярное выражение для поиска ссылок
  const urlPattern = /https?:\/\/[^\s]+/g;

  // Используем метод split для разбивки текста по ссылкам
  const parts = text.split(urlPattern);

  // Находим ссылки в тексте
  const links = text.match(urlPattern) || [];

  // Объединяем части текста и ссылки
  const result: (string | null)[] = [];

  for (let i = 0; i < parts.length; i++) {
    result.push(parts[i]);
    if (i < links.length) {
      result.push(links[i]);
    }
  }

  return result;
};
