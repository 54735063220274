import { useEffect, useMemo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '.';
import { getFavoritesCoursesThunk } from '../redux/courses/thunks';
import { CourseTypes, Statuses } from '../utils';
import { ICourse } from '../api/types';

const useFavoritesCourses = (types?: CourseTypes[]) => {
  const {
    coursesStore: {
      favoritesCourses: { data: favoritesCourses, statuse, cache: favoritesCoursesCache },
    },
    authStore: { isLoading },
  } = useAppSelector((state) => ({ coursesStore: state.coursesStore, authStore: state.authStore }));

  const dispatch = useAppDispatch();

  const isFavoritesCourses = Boolean(favoritesCourses?.length);

  const isFavoriteCoursesLoading =
    ((statuse === Statuses.idle || statuse === Statuses.loading) && !isFavoritesCourses) || isLoading;

  const refetch = () => {
    dispatch(getFavoritesCoursesThunk());
  };

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const favoritesCoursesBytype: (ICourse[] | undefined)[] = useMemo(() => {
    if (!types) return [];

    return types.map((type) => {
      return (
        favoritesCourses?.filter((course) => {
          if (favoritesCoursesCache[course.id] === false) return false;

          if (favoritesCoursesCache[course.id] === true) return course.course_type === type;

          if (!favoritesCoursesCache[course.id]) return course.course_type === type;

          return false;
        }) ?? []
      );
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favoritesCourses]);

  return {
    favoritesCourses,
    isFavoriteCoursesLoading,
    isFavoritesCourses,
    favoritesCoursesBytype,
    refetch,
  };
};

export default useFavoritesCourses;
