import React, { FC } from 'react';
import s from './styles.module.scss';
import { Stack, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import SliderCards, { TRenderCard, TSkeleton } from '../../molecules/SliderCards';
import { ICourse } from '../../../api/types';
import { KeyboardArrowRightIcon, NamesOfColors, Skeleton, Typography } from 'front-package-ui-kit';

export const TEST_ID = 'CourseSwiper';

interface IProps {
  courses: ICourse[];
  title: string;
  path: string;
  renderCard: TRenderCard<ICourse>;
  skeleton?: TSkeleton;
  loading?: boolean;
  renderLimit?: number;
  saveScrollKey: string;
}

const CourseSwiper: FC<IProps> = ({
  title,
  path,
  courses,
  renderCard,
  loading,
  skeleton,
  renderLimit,
  saveScrollKey,
}) => {
  return (
    <Box flexShrink={0} component="section">
      <header>
        <Stack className={s.title} direction="row" justifyContent="space-between" alignItems="center">
          {!loading && courses.length > 0 && (
            <Typography variant="h4" fontWeight={700} component="h2">
              {title}
            </Typography>
          )}

          {loading && <Skeleton width="45%" height="20px" borderRadius="4px" aria-label="Loading title" />}

          {!loading && courses.length > 1 && (
            <Link className={s.link} to={path} aria-label="View all courses">
              <Typography variant="text6" color="black" opacity={0.5} fontWeight={500} component="p">
                Все
              </Typography>

              <KeyboardArrowRightIcon size={16} color={NamesOfColors.grey50} />
            </Link>
          )}
        </Stack>
      </header>

      <SliderCards
        renderLimit={renderLimit}
        options={courses}
        renderCard={renderCard}
        loading={loading}
        skeleton={skeleton}
        saveScrollKey={saveScrollKey}
      />
    </Box>
  );
};

export default CourseSwiper;
