import { IAnalytics } from '../contexts/AnalyticsContext';
import { TPlatform } from '../models';
import { IStory } from '../pages/Stories/model/types';
import { ImagePaths } from '../utils';
import { ApiVersion, CourseTypes } from '../utils/constants/config';
import ApiClient from './ApiClient';
import {
  API_ANALYTICS_URL,
  API_APP_URL,
  API_BASE_URL,
  API_COMMENTS_URL,
  API_NOTIFICATION_URL,
  API_VIDEO_URL,
} from './config';
import { bannerListMock } from './mockData';
import {
  ICourse,
  IFavorites,
  IRating,
  IAuthRequestConfig,
  IResponse,
  ITokens,
  IUser,
  IWorkoutDetailse,
  ITrainer,
  IChangeWorkoutProgress,
  IChangeCourseProgress,
  IUserInfoForUpdate,
  PostFileResponse,
  ICourseDetailse,
  IRegistrationByPhoneConfig,
  IRegistrationByEmailConfig,
  IEmailConfirmationConfig,
  IPhoneConfirmationConfig,
  IAuthRefreshConfig,
  IGroups,
  ICountry,
  ISocial,
  IDateRange,
  IParameters,
  ISetSize,
  IUpdateSize,
  IVersion,
  IConstant,
  IOrderPayment,
  IOrder,
  IResponseRegisterPhone,
  IResponseRegisterEmail,
  IRequestRegisterEmail,
  IRequestRegisterPhone,
  IRequestEmailConfirmationCheck,
  IRequestPhoneConfirmation,
  IRequestEmailConfirmation,
  IFeedback,
  IReviews,
  IProduct,
  INotification,
  IPasswordRecovery,
  IReason,
  IChangeCourseProgressResponse,
  IParamClientAgreement,
  IClientAgreement,
  ISetClientAgreement,
  IAuthVkRequestConfig,
  IAuthGoogleRequestConfig,
  ISocialAuthResponse,
  IIntensity,
  IBankCard,
  ISetPushNotify,
  ISetHideStars,
  IGrade,
  IPromoBanner,
  ILevel,
  IOnboardStep,
  IImageRequestConfig,
  IComment,
  ICreateCommentRequest,
  IEditCommentRequest,
  IReferralProduct,
  IReferralLink,
  CommentGroupType,
  IGetCommentsGroupResponse,
  IUserActivity,
  ICreatetUserActivity,
  IEditUserActivity,
  IGetCommentsByGroupIdResponse,
  CommentSortType,
  IGotProduct,
  IBanner,
  IProductConfig,
  IGetAllStoriesResponse,
  TRenderSchemes,
  IFAQ,
  IBindingBankCardConfig,
  IUpdateAutopayConfig,
  IOrderBindingBankCard,
  IClientVersion,
} from './types';

const login = (config: IAuthRequestConfig) =>
  ApiClient.post<ITokens>(`${API_BASE_URL}/${ApiVersion.baseService}/clients/login`, config);

const updateAutopay = (config: IUpdateAutopayConfig) =>
  ApiClient.post<IResponse<boolean>>(`${API_BASE_URL}/${ApiVersion.baseService}/clients/autopay`, config);

const loginVk = (config: IAuthVkRequestConfig) =>
  ApiClient.post<ISocialAuthResponse>(`${API_BASE_URL}/${ApiVersion.baseService}/clients/vk`, config);

const loginGoogle = (config: IAuthGoogleRequestConfig) =>
  ApiClient.post<ISocialAuthResponse>(`${API_BASE_URL}/${ApiVersion.baseService}/clients/google`, config);

const loginByPhone = (config: IRequestRegisterPhone) =>
  ApiClient.post<IResponseRegisterPhone>(`${API_BASE_URL}/${ApiVersion.baseService}/clients/login-by-phone`, config);

const emailConfirmationCheck = (config: IRequestEmailConfirmationCheck) =>
  ApiClient.post<null>(`${API_BASE_URL}/${ApiVersion.baseService}/clients/email-confirmation-check`, config);

const phoneConfirmation = (config: IRequestPhoneConfirmation) =>
  ApiClient.post<ITokens>(`${API_BASE_URL}/${ApiVersion.baseService}/clients/phone-confirmation`, config);

const emailConfirmation = (config: IRequestEmailConfirmation) =>
  ApiClient.post<ITokens>(`${API_BASE_URL}/${ApiVersion.baseService}/clients/email-confirmation`, config);

const register = (config: IRequestRegisterEmail) =>
  ApiClient.post<IResponseRegisterEmail>(`${API_BASE_URL}/${ApiVersion.baseService}/clients/register`, config);

const getMyInfo = (platform: TPlatform) =>
  ApiClient.get<IResponse<IUser>>(`${API_BASE_URL}/${ApiVersion.baseService}/clients/me?platform=${platform}`);

const updateUserInfo = (userInfo: IUserInfoForUpdate) =>
  ApiClient.put<IResponse<IUser>>(`${API_BASE_URL}/${ApiVersion.baseService}/clients/me`, userInfo);

const updateOnboardStep = (onboardStep: IOnboardStep) =>
  ApiClient.post<IResponse<IUser>>(`${API_BASE_URL}/${ApiVersion.baseService}/clients/onboard`, onboardStep);

const deleteUserInfo = () => ApiClient.delete(`${API_BASE_URL}/${ApiVersion.appService}/clients`);

const uploadFile = (formData: FormData) =>
  ApiClient.post<IResponse<PostFileResponse>>(`${API_BASE_URL}/${ApiVersion.baseService}/files/upload`, formData);

const getAvatar = ({ identifier, size: { width, height, resize } }: IImageRequestConfig) =>
  ApiClient.get<Blob>(
    `${API_BASE_URL}/${ApiVersion.baseService}/${ImagePaths.clientAvatar}/${identifier}/${width}/${height}/${resize}`,
    {
      responseType: 'blob',
    }
  );

const getCourses = (courseType: CourseTypes) =>
  ApiClient.get<IResponse<ICourse[]>>(
    `${API_VIDEO_URL}/${ApiVersion.videoService}/courses/my?course_type=${courseType}`
  );

const getCoursesFast = () =>
  ApiClient.get<IResponse<ICourse[]>>(`${API_VIDEO_URL}/${ApiVersion.videoService}/courses/fast`);

const getGroups = () =>
  ApiClient.get<IResponse<IGroups[]>>(`${API_VIDEO_URL}/${ApiVersion.videoService}/courses/groups`);

const getCourseDetails = (id: string) =>
  ApiClient.get<IResponse<ICourseDetailse>>(`${API_VIDEO_URL}/${ApiVersion.videoService}/courses/${id}`);

const getStoryVideos = ({ from, to }: IDateRange) =>
  ApiClient.get<IResponse<IWorkoutDetailse[]>>(
    `${API_VIDEO_URL}/${ApiVersion.videoService}/courses/history?from=${from}&to=${to}`
  );

const getFullStoryVideos = () =>
  ApiClient.get<IResponse<IWorkoutDetailse[]>>(`${API_VIDEO_URL}/${ApiVersion.videoService}/courses/history`);

const removeWorkoutFromHistory = (workoutProgressId: number, courseProgressId: number) =>
  ApiClient.delete<IResponse<{ message: string }>>(
    `${API_VIDEO_URL}/${ApiVersion.videoService}/workouts-progress/${workoutProgressId}/courses-progress/${courseProgressId}`
  );

const getFavoritesCourses = () =>
  ApiClient.get<IResponse<ICourse[]>>(`${API_VIDEO_URL}/${ApiVersion.videoService}/courses/my?favorite=1`);

const getFavoritesWorkouts = () =>
  ApiClient.get<IResponse<IWorkoutDetailse[]>>(`${API_VIDEO_URL}/${ApiVersion.videoService}/workouts/my?favorite=1`);

const getWorkoutDetails = (id: string) =>
  ApiClient.get<IResponse<IWorkoutDetailse>>(`${API_VIDEO_URL}/${ApiVersion.videoService}/workouts/${id}`);

const getCountries = () => ApiClient.get<IResponse<ICountry[]>>(`${API_BASE_URL}/${ApiVersion.baseService}/countries`);

const getConstants = (constants: string[]) =>
  ApiClient.get<IResponse<IConstant[]>>(`${API_APP_URL}/${ApiVersion.appService}/constants`, {
    params: { key: constants },
  });

const getClientAgreement = () =>
  ApiClient.get<IResponse<IClientAgreement[]>>(`${API_BASE_URL}/${ApiVersion.baseService}/agreement/me`);

const setClientAgreement = (config: IParamClientAgreement) =>
  ApiClient.post<IResponse<ISetClientAgreement>>(`${API_BASE_URL}/${ApiVersion.baseService}/agreement`, config);

const getSocials = () => ApiClient.get<IResponse<ISocial[]>>(`${API_APP_URL}/${ApiVersion.appService}/socials`);

const getParameters = () =>
  ApiClient.get<IResponse<IParameters[]>>(`${API_BASE_URL}/${ApiVersion.baseService}/parameters`);

const removeParameters = (id: number) =>
  ApiClient.delete<IResponse<IParameters[]>>(`${API_BASE_URL}/${ApiVersion.baseService}/parameters/${id}`);

const getProducts = (params?: { [key: string]: string | undefined }) =>
  ApiClient.get<IResponse<IProduct[]>>(`${API_BASE_URL}/${ApiVersion.baseService}/products`, { params });

const getProduct = (id: number, params?: IProductConfig) =>
  ApiClient.get<IResponse<IProduct>>(`${API_BASE_URL}/${ApiVersion.baseService}/products/${id}`, {
    params,
  });

const updateParameters = ({ id, parameter_id, value, date }: IUpdateSize) =>
  ApiClient.put<IResponse<IParameters>>(
    `${API_BASE_URL}/${ApiVersion.baseService}/parameters/${id}?date=${date}&parameter_id=${parameter_id}&value=${value}`
  );

const setParameters = (config: ISetSize) =>
  ApiClient.post<IResponse<IParameters>>(`${API_BASE_URL}/${ApiVersion.baseService}/parameters`, config);

const clearParameters = ({ parameters }: { parameters: number[] }) =>
  ApiClient.post<IResponse<IParameters>>(`${API_BASE_URL}/${ApiVersion.baseService}/parameters-clear`, { parameters });

const setRating = (rating: IRating) => ApiClient.post(`${API_VIDEO_URL}/${ApiVersion.videoService}/ratings`, rating);

const deleteAccount = (config: IReason) =>
  ApiClient.post(`${API_BASE_URL}/${ApiVersion.baseService}/clients/reason`, config);

const setPushNotify = (config: ISetPushNotify) =>
  ApiClient.post(`${API_BASE_URL}/${ApiVersion.baseService}/clients/push-notify`, config);
const setHideStars = (config: ISetHideStars) =>
  ApiClient.post(`${API_BASE_URL}/${ApiVersion.baseService}/clients/hide-stars`, config);

const addFavorites = (config: IFavorites) =>
  ApiClient.post(`${API_VIDEO_URL}/${ApiVersion.videoService}/favorites`, config);

const removeFavorites = (config: IFavorites) =>
  ApiClient.post(`${API_VIDEO_URL}/${ApiVersion.videoService}/favorites/delete`, config);

const getOrder = (config: IOrder) =>
  ApiClient.post<IResponse<IOrderPayment>>(`${API_BASE_URL}/${ApiVersion.baseService}/orders-ext`, config);

const getOrderInform = (id: number) =>
  ApiClient.get<IResponse<IOrderPayment>>(`${API_BASE_URL}/${ApiVersion.baseService}/orders/${id}`);

const bindingBankCard = (config: IBindingBankCardConfig) =>
  ApiClient.post<IResponse<IOrderBindingBankCard>>(`${API_BASE_URL}/${ApiVersion.baseService}/orders/binding`, config);

const getTrainerInfo = (id: string) =>
  ApiClient.get<IResponse<ITrainer>>(`${API_BASE_URL}/${ApiVersion.baseService}/trainers/${id}`);

const getTrainers = () => ApiClient.get<IResponse<ITrainer[]>>(`${API_BASE_URL}/${ApiVersion.baseService}/trainers`);

const getCardList = () => ApiClient.get<IResponse<IBankCard[]>>(`${API_BASE_URL}/${ApiVersion.baseService}/cards`);

const deleteBankCard = (id: number) =>
  ApiClient.delete<IResponse<IBankCard[]>>(`${API_BASE_URL}/${ApiVersion.baseService}/cards/${id}`);

const setMaindBankCard = (id: number) =>
  ApiClient.get<IResponse<IBankCard[]>>(`${API_BASE_URL}/${ApiVersion.baseService}/cards/active/${id}`);

const changeWorkoutProgress = ({ id, status }: IChangeWorkoutProgress) =>
  ApiClient.post(`${API_VIDEO_URL}/${ApiVersion.videoService}/workouts/${id}/change-progress`, { status });

const changeCourseProgress = ({ id, status }: IChangeCourseProgress) =>
  ApiClient.post<IResponse<IChangeCourseProgressResponse>>(
    `${API_VIDEO_URL}/${ApiVersion.videoService}/courses/${id}/change-progress`,
    {
      status,
    }
  );

const phoneRegistration = (config: IRegistrationByPhoneConfig) =>
  ApiClient.post(`${API_BASE_URL}/${ApiVersion.baseService}/clients/login-by-phone`, config);

const emailRegistration = (config: IRegistrationByEmailConfig) =>
  ApiClient.post(`${API_BASE_URL}/${ApiVersion.baseService}/clients/register`, config);

const passwordRecovery = (config: IPasswordRecovery) =>
  ApiClient.post(`${API_BASE_URL}/${ApiVersion.baseService}/clients/restore`, config);

const confirmation = (config: IEmailConfirmationConfig | IPhoneConfirmationConfig) =>
  ApiClient.post<ITokens>(`${API_BASE_URL}/${ApiVersion.baseService}/clients/confirmation`, config);

const authRefresh = (config: IAuthRefreshConfig) =>
  ApiClient.post<ITokens>(`${API_BASE_URL}/${ApiVersion.baseService}/clients/refresh`, config);

const postAnalytics = (config: { data: IAnalytics[] }) =>
  ApiClient.post(`${API_ANALYTICS_URL}/${ApiVersion.analyticsService}/application/events`, config);

const postFeedback = (config: IFeedback) => ApiClient.post(`${API_BASE_URL}/${ApiVersion.baseService}/tickets`, config);

const postReviews = (config: IReviews) => ApiClient.post(`${API_BASE_URL}/${ApiVersion.baseService}/reviews`, config);

const getVersions = () => ApiClient.get<IResponse<IVersion[]>>(`${API_APP_URL}/${ApiVersion.appService}/versions`);

const setVersion = (config: IClientVersion) =>
  ApiClient.put<IResponse<IClientVersion>>(`${API_BASE_URL}/${ApiVersion.baseService}/clients/ver`, config);

const getNotifications = () =>
  ApiClient.get<IResponse<INotification[]>>(
    `${API_NOTIFICATION_URL}/${ApiVersion.notificationsService}/notifications/me`
  );

const readNotification = (id: number) =>
  ApiClient.put<IResponse<0 | 1>>(`${API_NOTIFICATION_URL}/${ApiVersion.notificationsService}/notifications/${id}`);

const removeNotification = (id: number) =>
  ApiClient.delete(`${API_NOTIFICATION_URL}/${ApiVersion.notificationsService}/notifications/${id}`);

const getIntensityDictionary = () =>
  ApiClient.get<IResponse<IIntensity[]>>(`${API_VIDEO_URL}/${ApiVersion.videoService}/intensity`);

const getLevelsDictionary = () =>
  ApiClient.get<IResponse<ILevel[]>>(`${API_VIDEO_URL}/${ApiVersion.videoService}/courses/levels`);

const getGradesList = () => ApiClient.get<IResponse<IGrade[]>>(`${API_APP_URL}/${ApiVersion.appService}/grades`);

const getPromoBanners = () => new Promise<IPromoBanner[]>((res) => res(bannerListMock));

const getRenderSchemes = () =>
  ApiClient.get<IResponse<TRenderSchemes[]>>(`${API_APP_URL}/${ApiVersion.appService}/configs
`);

const getReferralLink = () =>
  ApiClient.get<IResponse<IReferralLink[]>>(`${API_BASE_URL}/${ApiVersion.baseService}/referral/links`);

const createReferralLink = () =>
  ApiClient.post<IResponse<IReferralLink>>(`${API_BASE_URL}/${ApiVersion.baseService}/referral/links`);

const getReferralGift = (id: number) =>
  ApiClient.get<IResponse<IReferralLink[]>>(
    `${API_BASE_URL}/${ApiVersion.baseService}/referral/programs/${id}/present`
  );

const getReferralProducts = () =>
  ApiClient.get<IResponse<IReferralProduct[]>>(`${API_BASE_URL}/${ApiVersion.baseService}/referral/programs`);

const getIdsGotGifts = () =>
  ApiClient.get<IResponse<IGotProduct>>(`${API_BASE_URL}/${ApiVersion.baseService}/referral/presents/me`);

const increaseCountReferralLink = () =>
  ApiClient.post<IResponse<IReferralProduct[]>>(`${API_BASE_URL}/${ApiVersion.baseService}/referral/add`, {
    code: '86514cc36cf2ff2e31ad7bf60c4cdbc6',
  });

const getCommentGroupId = (groupType: CommentGroupType, id: number) =>
  ApiClient.get<IResponse<IGetCommentsGroupResponse>>(
    `${API_COMMENTS_URL}/${ApiVersion.commentsService}/groups/get/${groupType}/${id}`
  );

// const getComments = () =>
//   ApiClient.get<IResponse<IComment[]>>(`${API_COMMENTS_URL}/${ApiVersion.commentsService}/comments`);

const getCommetsByGroupId = (id: number, page: number = 1, sort: CommentSortType = CommentSortType.DATE_DESC) =>
  ApiClient.get<IGetCommentsByGroupIdResponse>(
    `${API_COMMENTS_URL}/${ApiVersion.commentsService}/comments-group/${id}?page=${page}&sort=${sort}`
  );

const uploadCommentImage = (formData: FormData) =>
  ApiClient.post<IResponse<PostFileResponse>>(
    `${API_COMMENTS_URL}/${ApiVersion.commentsService}/files/upload`,
    formData
  );

const createComment = (config: ICreateCommentRequest) =>
  ApiClient.post<IResponse<IComment>>(`${API_COMMENTS_URL}/${ApiVersion.commentsService}/comments`, config);

const editComment = (config: IEditCommentRequest) =>
  ApiClient.put<IResponse<IComment>>(`${API_COMMENTS_URL}/${ApiVersion.commentsService}/comments/${config.id}`, config);

const likeComment = (id: number) =>
  ApiClient.get<IResponse<{ message: string }>>(
    `${API_COMMENTS_URL}/${ApiVersion.commentsService}/comments/${id}/like`
  );

const unlikeComment = (id: number) =>
  ApiClient.delete<IResponse<{ message: string }>>(
    `${API_COMMENTS_URL}/${ApiVersion.commentsService}/comments/${id}/like`
  );

const deleteComment = (id: number) =>
  ApiClient.delete(`${API_COMMENTS_URL}/${ApiVersion.commentsService}/comments/${id}`);

const getUserActivities = () =>
  ApiClient.get<IResponse<IUserActivity[]>>(`${API_BASE_URL}/${ApiVersion.baseService}/client-activities`);

const getUserActivityById = (id: number) =>
  ApiClient.get<IResponse<IUserActivity>>(`${API_BASE_URL}/${ApiVersion.baseService}/client-activities${id}`);

const createtUserActivity = (config: ICreatetUserActivity) =>
  ApiClient.post<IResponse<IUserActivity>>(`${API_BASE_URL}/${ApiVersion.baseService}/client-activities`, config);

const deleteUserActivity = (id: number) =>
  ApiClient.delete(`${API_BASE_URL}/${ApiVersion.baseService}/client-activities/${id}`);

const editUserActivity = ({ id, ...config }: IEditUserActivity) =>
  ApiClient.put(`${API_BASE_URL}/${ApiVersion.baseService}/client-activities/${id}`, config);

const getBanners = () => ApiClient.get<IResponse<IBanner[]>>(`${API_APP_URL}/${ApiVersion.baseService}/banners`);

const getAllStories = () => ApiClient.get<IGetAllStoriesResponse>(`${API_APP_URL}/${ApiVersion.baseService}/stories`);

const getStoryById = (id: number) =>
  ApiClient.get<IResponse<IStory>>(`${API_APP_URL}/${ApiVersion.baseService}/stories/${id}`);

const getStoryViewed = (ids: number[]) =>
  ApiClient.post<IResponse<{ message: string }>>(`${API_APP_URL}/${ApiVersion.baseService}/stories/viewed`, { ids });

const getCourseFAQ = (params: { course_id: string }) =>
  ApiClient.get<IResponse<IFAQ[]>>(`${API_VIDEO_URL}/${ApiVersion.videoService}/courses/faqs`, { params });

const api = {
  deleteAccount,
  login,
  loginVk,
  loginGoogle,
  getOrder,
  passwordRecovery,
  setClientAgreement,
  getClientAgreement,
  getMyInfo,
  getProducts,
  getProduct,
  deleteUserInfo,
  getOrderInform,
  removeParameters,
  getConstants,
  updateParameters,
  getSocials,
  setParameters,
  getAvatar,
  getCourses,
  getStoryVideos,
  getFullStoryVideos,
  getParameters,
  getCountries,
  getCourseDetails,
  getFavoritesCourses,
  getWorkoutDetails,
  changeCourseProgress,
  // getParameterList,
  setRating,
  addFavorites,
  removeFavorites,
  getTrainerInfo,
  changeWorkoutProgress,
  updateUserInfo,
  uploadFile,
  phoneRegistration,
  emailRegistration,
  confirmation,
  authRefresh,
  getGroups,
  postAnalytics,
  getVersions,
  setVersion,
  register,
  loginByPhone,
  emailConfirmationCheck,
  phoneConfirmation,
  emailConfirmation,
  postFeedback,
  postReviews,
  getNotifications,
  readNotification,
  removeNotification,
  getIntensityDictionary,
  getCardList,
  deleteBankCard,
  setPushNotify,
  setHideStars,
  getFavoritesWorkouts,
  getGradesList,
  getPromoBanners,
  getLevelsDictionary,
  clearParameters,
  updateOnboardStep,
  getTrainers,
  getReferralLink,
  getReferralProducts,
  getReferralGift,
  getIdsGotGifts,
  createReferralLink,
  increaseCountReferralLink,
  getCommentGroupId,
  getCommetsByGroupId,
  // getComments,
  createComment,
  editComment,
  likeComment,
  unlikeComment,
  deleteComment,
  uploadCommentImage,
  getUserActivities,
  createtUserActivity,
  deleteUserActivity,
  editUserActivity,
  getUserActivityById,
  getBanners,
  getAllStories,
  getStoryById,
  getStoryViewed,
  getCoursesFast,
  getRenderSchemes,
  removeWorkoutFromHistory,
  getCourseFAQ,
  bindingBankCard,
  updateAutopay,
  setMaindBankCard,
};

export default api;
