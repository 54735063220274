import { ProductCard } from 'front-package-ui-kit';
import { useNavigate } from 'react-router-dom';

import {
  CourseOrWorkoutTypes,
  CourseTypes,
  getCourseLevel,
  getCourseStatuses,
  ImagePaths,
  Paths,
  PRODUCT_CARD_IMAGE_SIZES,
  ResizeType,
  withAnalytic,
} from '../../../utils';
import { useBottomSheet, useGroups, useOnFavoritesClickHandler } from '../../../hooks';
import { openPaySubscribeBottomSheet } from '../../organisms/PaySubscribeBottomSheet';
import { WorkoutCardSize, unit } from './config';
import { ICourse } from '../../../api/types';
import defaultCover from '../../../assets/image/defaultCover.png';
import { ImageCover } from '../..';

const { singleWorkouts } = Paths;

// export type TWorkoutCardSize = 'L' | 'M' | 'S';

const BOTTOM_ICON_TYPES_BY_COURSE_TYPE = {
  [CourseTypes.single]: 'Videocamera',
  [CourseTypes.complex]: 'Play',
  [CourseTypes.multiple]: 'Videocamera',
  [CourseTypes.broadcast]: 'Fire',
} as const;

export type TWorkoutCardSize = 'M' | 'S';

interface SingleWorkoutCardProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  course: ICourse;
  size?: TWorkoutCardSize;
  fullWidth?: boolean;
}

export const TEST_ID = 'SingleWorkoutCard';

const SingleWorkoutCard = ({
  course,
  size = WorkoutCardSize.M,
  fullWidth,
  onClick,
  ...props
}: SingleWorkoutCardProps) => {
  const { onOpenBottomSheet } = useBottomSheet();

  const { name, course_type: courseType, duration, cover, levels, workouts, is_public: isPublicWorkout } = course;

  const { isAvailable: isCourseAvailable } = getCourseStatuses(course);

  const isOpenTraining = isCourseAvailable || isPublicWorkout;

  const [workout] = workouts;

  const {
    groupsByType: {
      new: [novelty],
    },
  } = useGroups();

  const isNew = course.groups.includes(+novelty?.id);

  const { isAvailable } = getCourseStatuses(course);

  const { onFavoritesClickHandler, isFavorite } = useOnFavoritesClickHandler(
    workout?.favorite,
    workout?.id,
    CourseOrWorkoutTypes.workout
  );

  const bottomIconText = duration ? `${duration} ${unit[courseType]}` : '';

  const bottomIconType = BOTTOM_ICON_TYPES_BY_COURSE_TYPE[courseType];

  const level = getCourseLevel(levels);

  const rowInfo = [
    courseType === 2 ? 'тренировка' : 'комплекс',
    workout?.inventory ? 'инвентарь' : 'без инвентаря',
    level ? level.title : '',
  ];

  const navigate = useNavigate();

  return (
    <ProductCard
      onClick={(e) => {
        if (onClick) onClick(e);

        if (isOpenTraining) {
          navigate(`${singleWorkouts}/0/${workout?.id}`);

          return;
        }

        onOpenBottomSheet(openPaySubscribeBottomSheet);
      }}
      bottomIconType={bottomIconType}
      bottomIconText={bottomIconText}
      rowInfo={{ options: rowInfo }}
      title={name}
      size={size}
      lock={!isAvailable}
      novelty={isNew}
      topRightIconOptions={{
        type: 'Favorite',
        onClick: onFavoritesClickHandler,
        isActive: isFavorite,
      }}
      cover={
        <ImageCover
          img={{
            baseApiService: 'VIDEO',
            imagePath: ImagePaths.courseCover,
            name: cover,
            width: PRODUCT_CARD_IMAGE_SIZES[size].width,
            height: PRODUCT_CARD_IMAGE_SIZES[size].height,
            resize: ResizeType.resizeBothCenter,
          }}
          defaultImg={defaultCover}
        />
      }
      fullWidth={fullWidth}
      bottomText={bottomIconText}
      {...props}
    />
  );
};

export default withAnalytic(SingleWorkoutCard);
