import { Box, StackProps } from '@mui/material';
import { BadgeCounter, Button, FilterIcon, Skeleton } from 'front-package-ui-kit';
import { FC, useMemo, useState } from 'react';
import { ClearFiltersIcon } from '../../../assets/icons';
import { IButtonsFilterOptions } from '../../../hooks/useFilter';
import s from './styles.module.scss';
import { QuickFilterItem } from '../..';

const skeletons = [
  { width: '36px', height: '36px', borderRadius: '12px' },
  { width: '124px', height: '36px', borderRadius: '12px' },
  { width: '92px', height: '36px', borderRadius: '12px' },
  { width: '100px', height: '36px', borderRadius: '12px' },
  { width: '124px', height: '36px', borderRadius: '12px' },
];

export const TEST_ID = 'QuickFilters';

interface IProps extends Omit<StackProps, 'className'> {
  filters?: IButtonsFilterOptions[];
  onClearClick: () => void;
  onOpenFiltersButtonClick?: () => void;
  numberOfFilters?: number;
  loading?: boolean;
  prefilledFilterValue?: string;
}

const QuickFilters: FC<IProps> = ({
  filters,
  onClearClick,
  onOpenFiltersButtonClick,
  numberOfFilters,
  loading,
  prefilledFilterValue,
  ...props
}) => {
  const [activeFilterOptionsTitleForOrder, setActiveFilterOptionsTitleForOrder] = useState('');

  return (
    <Box className={s.quickFilters} data-testid={TEST_ID} {...props}>
      <Box className={s.scrollContainer}>
        {!loading && (
          <>
            <Button
              size="S"
              onClick={onOpenFiltersButtonClick}
              variant="outlined"
              color="grey20"
              buttonType="iconButton"
              style={{ order: 1, flexShrink: 0, position: 'relative', padding: '8px 10px' }}
            >
              <FilterIcon size={16} />

              {Boolean(numberOfFilters) && (
                <Box position="absolute" width="16px" height="16px" top="0px" right="-5px">
                  <BadgeCounter color="lime">{`${numberOfFilters}`}</BadgeCounter>
                </Box>
              )}
            </Button>

            {filters?.map((filterOptions, i) => {
              // Для активного фильтра присваиваем order 1, чтобы он всегда был первым
              const isActive = filterOptions.title === activeFilterOptionsTitleForOrder;

              return (
                <Box
                  style={{
                    order: isActive ? 1 : i + 2, // Активный фильтр перемещаем в начало, остальные сохраняют свой порядок
                    flexShrink: 0,
                  }}
                  key={filterOptions.title}
                >
                  <QuickFilterItem
                    prefilledFilterValue={prefilledFilterValue}
                    filterOptions={filterOptions}
                    setActiveFilterOptionsTitleForOrder={setActiveFilterOptionsTitleForOrder}
                  />
                </Box>
              );
            })}

            {(numberOfFilters ?? 0) > 0 && (
              <Button
                size="S"
                onClick={onClearClick}
                variant="outlined"
                color="grey20"
                buttonType="iconButton"
                style={{ order: 200, flexShrink: 0 }}
              >
                <ClearFiltersIcon />
              </Button>
            )}
          </>
        )}

        {loading && (
          <>
            {skeletons.map((skeleton, i) => (
              <Box key={i} style={{ flexShrink: 0 }}>
                <Skeleton {...skeleton} />
              </Box>
            ))}
          </>
        )}
      </Box>
    </Box>
  );
};

export default QuickFilters;
