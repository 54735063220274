import { Navigate, Route, Routes } from 'react-router-dom';
import { PrivateRoute } from '../components';
import {
  AboutApp,
  CollectionsDetails,
  Collections,
  Comments,
  CourseDetails,
  CourseVideos,
  Courses,
  CreateActivityPage,
  Edit,
  Favorites,
  FeedbackMethod,
  Feedback,
  LicenseAgreement,
  LoginRegister,
  Main,
  ManageSubscribe,
  NeedUpdate,
  Notifications,
  Onboarding,
  OrderStatus,
  OrderStatusSite,
  PaySubscribe,
  PrivacyPolicy,
  ProcessingPersonalData,
  Profile,
  ReferralProgramme,
  SettingNotifications,
  Settings,
  SingleWorkoutsAll,
  SingleWorkoutsGroups,
  StorySizes,
  StoryWeight,
  Test,
  UserAgreement,
  VideoDetails,
  VideoPlayer,
  OpenSupport,
  WidgetSettings,
  PaymentHistory,
  Subscribe,
  NotFound,
  Stepmeter,
  StepmeterDistanceUpdate,
  ReferralFriends,
} from '../pages';

import { Paths } from '../utils';

const {
  main,
  courses,
  courseVideos,
  singleWorkouts,
  video,
  profile,
  login,
  edit,
  favoritesAll,
  settings,
  videoDetails,
  storySizes,
  storyWeight,
  needUpdate,
  aboutPage,
  licenseAgreement,
  userAgreement,
  onboarding,
  privacyPolicy,
  orders,
  ordersSite,
  paySubscribe,
  notifications,
  processingPersonalData,
  manageSubscribe,
  settingNotification,
  collections,
  feedbackMethod,
  feedback,
  referralProgramme,
  testPage,
  comments,
  createActivity,
  openSupport,
  widgetSettings,
  paymentHistory,
  subscribe,
  stepmeter,
  distance,
  referralFriends,
} = Paths;

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={main} />} />
      <Route path={main} element={<PrivateRoute component={Main} />} />
      <Route path={login} element={<LoginRegister />} />
      <Route path={processingPersonalData} element={<ProcessingPersonalData />} />
      <Route path={onboarding} element={<PrivateRoute component={Onboarding} />} />
      <Route path={courses} element={<PrivateRoute component={Courses} />} />
      <Route path={paySubscribe} element={<PrivateRoute component={PaySubscribe} />} />
      <Route path={`${courses}/:id`} element={<PrivateRoute component={CourseDetails} />} />
      <Route path={`${courses}/:id${courseVideos}`} element={<PrivateRoute component={CourseVideos} />} />
      <Route path={`${courses}/:id${videoDetails}/:id`} element={<PrivateRoute component={VideoDetails} />} />
      <Route path={`${courses}/:id${videoDetails}/:id${comments}`} element={<PrivateRoute component={Comments} />} />
      <Route path={`${profile}${videoDetails}/:id`} element={<PrivateRoute component={VideoDetails} />} />
      <Route path={`${singleWorkouts}/:groupId/:id`} element={<PrivateRoute component={VideoDetails} />} />
      <Route path={`${singleWorkouts}/:groupId/:id${comments}`} element={<PrivateRoute component={Comments} />} />
      <Route path={`${orders}/:id`} element={<OrderStatus />} />
      <Route path={`${ordersSite}/:id`} element={<OrderStatusSite />} />
      <Route path={singleWorkouts} element={<PrivateRoute component={SingleWorkoutsGroups} />} />
      <Route path={`${singleWorkouts}/:groupId`} element={<PrivateRoute component={SingleWorkoutsAll} />} />
      <Route path={`${video}/:id/:playerType`} element={<PrivateRoute component={VideoPlayer} />} />
      <Route path={profile} element={<PrivateRoute component={Profile} />} />
      <Route path={`${profile}${edit}`} element={<PrivateRoute component={Edit} />} />
      <Route path={`${profile}${notifications}`} element={<PrivateRoute component={Notifications} />} />
      <Route path={`${profile}${favoritesAll}/:courseType`} element={<PrivateRoute component={Favorites} />} />
      <Route path={`${profile}${settings}`} element={<PrivateRoute component={Settings} />} />
      <Route path={`${profile}${feedback}`} element={<PrivateRoute component={Feedback} />} />
      <Route path={`${profile}${stepmeter}`} element={<PrivateRoute component={Stepmeter} />} />
      <Route
        path={`${profile}${stepmeter}${distance}/:id/:action`}
        element={<PrivateRoute component={StepmeterDistanceUpdate} />}
      />
      <Route path={`${profile}${storySizes}`} element={<PrivateRoute component={StorySizes} />} />
      <Route path={`${profile}${storyWeight}`} element={<PrivateRoute component={StoryWeight} />} />
      <Route path={`${courses}/:id${comments}`} element={<PrivateRoute component={Comments} />} />
      <Route path={needUpdate} element={<NeedUpdate />} />
      <Route path={`${profile}${settings}${aboutPage}`} element={<PrivateRoute component={AboutApp} />} />
      <Route
        path={`${profile}${settings}${settingNotification}`}
        element={<PrivateRoute component={SettingNotifications} />}
      />
      <Route
        path={`${profile}${settings}${referralProgramme}`}
        element={<PrivateRoute component={ReferralProgramme} />}
      />
      <Route path={manageSubscribe} element={<PrivateRoute component={ManageSubscribe} />} />
      <Route
        path={`${profile}${settings}${aboutPage}${licenseAgreement}`}
        element={<PrivateRoute component={LicenseAgreement} />}
      />
      <Route path={`${profile}${settings}${aboutPage}${privacyPolicy}`} element={<PrivacyPolicy />} />
      <Route path={`${profile}${settings}${aboutPage}${userAgreement}`} element={<UserAgreement />} />
      <Route
        path={`${profile}${createActivity}/:action/:date`}
        element={<PrivateRoute component={CreateActivityPage} />}
      />
      <Route path={`${collections}/:parentPage`} element={<PrivateRoute component={Collections} />} />
      <Route path={`${collections}/:parentPage/:id`} element={<PrivateRoute component={CollectionsDetails} />} />
      <Route path={feedbackMethod} element={<PrivateRoute component={FeedbackMethod} />} />
      <Route path={openSupport} element={<OpenSupport />} />
      <Route path={testPage} element={<PrivateRoute component={Test} />} />
      <Route path="*" element={<NotFound />} />
      <Route path={`${profile}${widgetSettings}`} element={<PrivateRoute component={WidgetSettings} />} />
      <Route path={paymentHistory} element={<PrivateRoute component={PaymentHistory} />} />
      <Route path={subscribe} element={<PrivateRoute component={Subscribe} />} />
      <Route path={referralFriends} element={<PrivateRoute component={ReferralFriends} />} />
    </Routes>
  );
};

export default Router;
