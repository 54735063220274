import React, { FC, memo, useMemo } from 'react';
import {
  CourseOrWorkoutTypes,
  getCountOfCompletedWorkouts,
  getCourseLevel,
  getCourseStatuses,
  getDeclinationText,
  getLevelValues,
  getNumberOfWorkouts,
  ImagePaths,
  Paths,
  PRODUCT_CARD_IMAGE_SIZES,
  ResizeType,
  VariantsOfParticipantsText,
  VariantsOfWorkoutText,
  withAnalytic,
} from '../../../utils';
import { ICourse } from '../../../api/types';
import defaultCover from '../../../assets/image/defaultCover.png';
import { useGroups, useOnFavoritesClickHandler } from '../../../hooks';
import { ProductCard, Typography } from 'front-package-ui-kit';
import { useNavigate } from 'react-router-dom';
import { CourseParticipants, ImageCover } from '../..';

const { courses } = Paths;

export type TCourseCardSize = 'L' | 'M';

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  course: ICourse;
  size: TCourseCardSize;
  fullWidth?: boolean;
}

const CourseCard: FC<IProps> = ({ course, onClick, size, ...props }) => {
  const { name, id, cover, favorite, levels, current_performing: currentPerforming } = course;

  const navigate = useNavigate();

  const { isAvailable } = useMemo(() => {
    return getCourseStatuses(course);
  }, [course]);

  const { onFavoritesClickHandler, isFavorite } = useOnFavoritesClickHandler(favorite, id, CourseOrWorkoutTypes.course);

  const {
    groupsByType: {
      new: [novelty],
    },
  } = useGroups();

  const isNew = course.groups.includes(+novelty?.id);

  const levelOptions = useMemo(() => {
    const courseLevel = getCourseLevel(levels);

    const title = courseLevel?.title ?? '';

    const level = getLevelValues(courseLevel?.id);

    return { title, level };
  }, [levels]);

  const progressOptions = useMemo(() => {
    const { status } = getCourseStatuses(course);

    const number = getNumberOfWorkouts(course.workouts);

    const numberOfCompleted = getCountOfCompletedWorkouts(course.workouts);

    const title =
      status === 'completed'
        ? 'пройдено'
        : status === 'inProgress'
        ? VariantsOfWorkoutText.five
        : getDeclinationText(number ?? 0, VariantsOfWorkoutText);

    return { status, number, numberOfCompleted, title };
  }, [course]);

  const subTitle = useMemo(() => {
    return course?.short_description || undefined;
  }, [course?.short_description]);

  const participantsOptions = useMemo(() => {
    const avatars = typeof course.avatars === 'string' && course.avatars !== '' ? course.avatars.split(',') : [];

    const title = getDeclinationText(currentPerforming, VariantsOfParticipantsText);

    return { title, participants: <CourseParticipants avatars={avatars} clientsCount={currentPerforming} /> };
  }, [currentPerforming, course.avatars]);

  const rowInfo = [levelOptions.title ? levelOptions.title : ''];

  return (
    <ProductCard
      onClick={(e) => {
        if (onClick) onClick(e);

        navigate(`${courses}/${id}`);
      }}
      rowInfo={{
        options: rowInfo,
        customItem: (
          <>
            <Typography fontWeight={500} variant="text7">
              {progressOptions.numberOfCompleted}
            </Typography>

            <Typography opacity={0.5} fontWeight={500} variant="text7">
              {`/${progressOptions.number} тренировок`}
            </Typography>
          </>
        ),
      }}
      title={name}
      subTitle={subTitle}
      lock={!isAvailable}
      novelty={isNew}
      topRightIconOptions={{
        type: 'Favorite',
        onClick: onFavoritesClickHandler,
        isActive: isFavorite,
      }}
      cover={
        <ImageCover
          img={{
            baseApiService: 'VIDEO',
            imagePath: ImagePaths.courseCover,
            name: cover,
            width: PRODUCT_CARD_IMAGE_SIZES[size].width,
            height: PRODUCT_CARD_IMAGE_SIZES[size].height,
            resize: ResizeType.resizeBothCenter,
          }}
          defaultImg={defaultCover}
          maxZIndexPreloader
        />
      }
      participantsOptions={participantsOptions}
      levelOptions={levelOptions}
      progressOptions={progressOptions}
      size={size}
      {...props}
    />
  );
};

export default memo(withAnalytic(CourseCard));
