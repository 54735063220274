import { TypeDurationProduct } from '../../api/types';
import { TVariants } from '../getNumberOfWorkoutsText';

export enum VariantsOfEveryText {
  one = 'каждый',
  two = 'каждые',
  five = 'каждые',
}

export enum VariantsOfParticipantsText {
  one = 'участник',
  two = 'участника',
  five = 'участников',
}

export enum VariantsOfActiveDaysText {
  one = 'активный день',
  two = 'активных дня',
  five = 'активных дней',
}

export enum VariantsOfWorkoutText {
  one = 'тренировка',
  two = 'тренировки',
  five = 'тренировок',
}

export enum VariantsOfMinuteText {
  one = 'минута',
  two = 'минуты',
  five = 'минут',
}

export enum VariantsOfWeekText {
  one = 'неделя',
  two = 'недели',
  five = 'недель',
}

export enum VariantsOfDayText {
  one = 'день',
  two = 'дня',
  five = 'дней',
}

export enum VariantsOfYersText {
  one = 'год',
  two = 'года',
  five = 'лет',
}

export enum VariantsOfMonthText {
  one = 'месяц',
  two = 'месяца',
  five = 'месяцев',
}

export enum VariantsOfCommentsText {
  one = 'комментарий',
  two = 'комментария',
  five = 'комментариев',
}

export enum CollectionsText {
  one = 'подборка',
  two = 'подборки',
  five = 'подборок',
}

export enum DefaultsText {
  one = '',
  two = '',
  five = '',
}

export const declinationTextByProductUnit: Record<TypeDurationProduct, TVariants> = {
  '': DefaultsText,
  day: VariantsOfDayText,
  week: VariantsOfWeekText,
  month: VariantsOfMonthText,
  year: VariantsOfYersText,
};

export enum VariantsOfFriendsText {
  one = 'друга',
  two = 'друга',
  five = 'друзей',
}
