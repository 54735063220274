import { ReactNode } from 'react';
import { Box, Stack, StackProps } from '@mui/material';
import cn from 'classnames';

import { GoBackButtonProps } from '../../molecules/GoBackButton';
import { GoBackButton } from '../..';

import s from './styles.module.scss';

interface NavBarProps extends StackProps {
  goBackButton?: boolean;
  children?: ReactNode;
  goBackButtonProps?: GoBackButtonProps;
  onBackClick?: () => void;
  rightAction?: ReactNode;
  center?: boolean;
  theme?: 'dark' | 'light';
  absolute?: boolean;
  blur?: boolean;
}

const NavBar = ({
  goBackButton,
  children,
  goBackButtonProps,
  onBackClick,
  center,
  rightAction,
  theme = 'light',
  absolute = false,
  blur = false,
  ...props
}: NavBarProps) => {
  const classes = cn(s.navBar, {
    [s.navBarWithGoBack]: goBackButton,
    [s.navBarWithRightAction]: rightAction,
    [s.center]: center && (goBackButton || rightAction),
    [s.absolute]: absolute,
    [s.blur]: blur,
  });

  return (
    <Stack component="nav" className={classes} direction="row" alignItems="center" {...props}>
      {goBackButton && (
        <div className={s.goBackBtn}>
          <GoBackButton onClick={onBackClick} white={theme === 'dark' ? true : false} {...goBackButtonProps} />
        </div>
      )}

      {children}

      {rightAction && <Box className={s.rightAction}>{rightAction}</Box>}
    </Stack>
  );
};

export default NavBar;
