import { NamesOfColors, SearchIcon } from 'front-package-ui-kit';

import IconButtonUI from '../../atoms/IconButtonUI';
import CloseIcon from './CloseIcon';

import s from './styles.module.scss';

interface SearchInputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  value: string;
  placeholder?: string;
  isSearchIcon?: Boolean;
  handleChange: (value: string) => void;
  handleClear: () => void;
  theme?: 'dark' | 'light';
}

const SearchInput = ({
  handleChange,
  handleClear,
  theme = 'light',
  value,
  placeholder = 'Поиск',
  isSearchIcon = false,
  ...props
}: SearchInputProps) => {
  const onChangeHandle = ({ target: { value: val } }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    handleChange(val);
  };

  const clearInput = () => {
    handleClear();
  };

  return (
    <div className={s.container} data-theme={theme}>
      <input
        placeholder={placeholder}
        value={value}
        onChange={onChangeHandle}
        className={s.inputComponent}
        type="text"
        {...props}
      />

      {Boolean(value.length) && (
        <IconButtonUI size={24} onClick={clearInput}>
          <CloseIcon fill={theme === 'dark' ? 'white' : '#1D1D1D'} opacity={theme === 'dark' ? '0.5' : '0.3'} />
        </IconButtonUI>
      )}
      {isSearchIcon && (
        <IconButtonUI size={24} onClick={clearInput}>
          <SearchIcon color={NamesOfColors.grey100} />
        </IconButtonUI>
      )}
    </div>
  );
};

export default SearchInput;
