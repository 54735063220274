import { ProfileIconNav, CoursesIconNav, SingleWorkoutsIconNav, MainIconNav } from '../../assets/icons';

export enum Paths {
  main = '/main',
  courses = '/courses',
  courseVideos = '/course_videos',
  singleWorkouts = '/single_workouts',
  video = '/video',
  videoDetails = '/video_details',
  profile = '/profile',
  login = '/login',
  passwordRecovery = '/password_recovery',
  edit = '/edit',
  favoritesAll = '/favorites_all',
  feedback = '/feedback',
  settings = '/settings',
  stepmeter = '/stepmeter',
  distance = '/distance',
  storyWeight = '/story_weight',
  storySizes = '/story_sizes',
  needUpdate = '/need_update',
  aboutPage = '/about_page',
  userAgreement = '/user_agreement',
  licenseAgreement = '/license_agreement',
  privacyPolicy = '/privacy_policy',
  onboarding = '/onboarding',
  paySubscribe = '/pay_subscribe',
  orders = '/orders',
  ordersSite = '/orders_site',
  notifications = '/notifications',
  processingPersonalData = '/processing_personal_data',
  manageSubscribe = '/manage_subscribe',
  settingNotification = '/setting_notification',
  collections = '/collections',
  feedbackMethod = '/feedback_method',
  referralProgramme = '/referral_programme',
  testPage = '/test_page',
  comments = '/comments',
  createActivity = '/create_activity',
  stories = '/stories',
  openSupport = '/open_support',
  widgetSettings = '/widget_settings',
  paymentHistory = '/payment_history',
  subscribe = '/subscribe',
  referralFriends = '/referral_friends',
}

export enum RenderSchemaVariants {
  collections = 'collections',
  group = 'group',
  banner = 'banner',
}

export enum VirtualGroups {
  level = 'level',
  duration = 'duration',
}

export enum SingleWorkoutsGroupsOptions {
  all = 'all',
  novelty = 'novelty',
  popular = 'popular',
}

export enum RegistrationType {
  phone = 'По телефону',
  email = 'По почте',
}

export enum LocalStorage {
  theme = 'theme',
}

export enum Theme {
  dark = 'dark',
  light = 'light',
}

export enum CourseOrWorkoutTypes {
  course = 'course',
  workout = 'workout',
}

export enum CourseTypes {
  multiple = 1,
  single = 2,
  complex = 3,
  broadcast = 4,
}

export const COURSE_TYPE_NAMES = {
  '1': 'курс',
  '2': 'тренировка',
  '3': 'комплекс',
  '4': 'эфиры',
} as const;

export enum GendersTypes {
  male = 'Мужской',
  female = 'Женский',
}

export enum GendersTypesNumerical {
  male = 1,
  female = 0,
}

export enum CourseOrWorkoutNames {
  course = 'Программы',
  workout = 'Тренировки',
}

export enum ParametersValuesLat {
  height = 'height',
  weight = 'weight',
  chest = 'chest',
  waist = 'waist',
  hips = 'hips',
}

const PARAMETER_TYPE_HEIGHT = { id: 1, name: 'Рост', declination: { genitive: 'роста' } } as const;
const PARAMETER_TYPE_WEIGHT = { id: 2, name: 'Вес', declination: { genitive: 'веса' } } as const;
const PARAMETER_TYPE_CHEST = { id: 3, name: 'Грудь', declination: { genitive: 'груди' } } as const;
const PARAMETER_TYPE_WAIST = { id: 4, name: 'Талия', declination: { genitive: 'талии' } } as const;
const PARAMETER_TYPE_HIPS = { id: 5, name: 'Бедра', declination: { genitive: 'бёдер' } } as const;
const PARAMETER_TYPE_GOAL = { id: 6, name: 'Цель', declination: { genitive: 'цели' } } as const;
const PARAMETER_TYPE_LEVEL = { id: 7, name: 'Уровень', declination: { genitive: 'уровня' } } as const;

export type TParameterTypeLevel = typeof PARAMETER_TYPE_LEVEL;
export type TParameterTypeGoal = typeof PARAMETER_TYPE_GOAL;
export type TParameterTypeHips = typeof PARAMETER_TYPE_HIPS;
export type TParameterTypeWaist = typeof PARAMETER_TYPE_WAIST;
export type TParameterTypeChest = typeof PARAMETER_TYPE_CHEST;
export type TParameterTypeWeight = typeof PARAMETER_TYPE_WEIGHT;
export type TParameterTypeHeight = typeof PARAMETER_TYPE_HEIGHT;

export const PARAMETERS_TYPES = {
  height: PARAMETER_TYPE_HEIGHT,
  weight: PARAMETER_TYPE_WEIGHT,
  chest: PARAMETER_TYPE_CHEST,
  waist: PARAMETER_TYPE_WAIST,
  hips: PARAMETER_TYPE_HIPS,
  goal: PARAMETER_TYPE_GOAL,
  level: PARAMETER_TYPE_LEVEL,
} as const;

const ParametersTypesIDs = Object.values(PARAMETERS_TYPES).map(({ id }) => id);

export type TParametersTypes = typeof PARAMETERS_TYPES;

export type TParametersTypesIDs = typeof ParametersTypesIDs;

export enum ParametersValuesDefault {
  height = 160,
  weight = 70,
  chest = 90,
  waist = 60,
  hips = 90,
}

export enum WorkoutsProgressStatuses {
  notStarted = 1,
  completed = 2,
  inProgress = 3,
  restarted = 4,
}

export enum CourseProgressStatus {
  notStarted = 1,
  inProgress = 2,
  completed = 3,
}

export const bottomNavigationOptions = [
  { path: Paths.main, label: 'Главная', Icon: MainIconNav },
  { path: Paths.singleWorkouts, label: 'Тренировки', Icon: SingleWorkoutsIconNav },
  { path: Paths.courses, label: 'Программы', Icon: CoursesIconNav },
  { path: Paths.profile, label: 'Профиль', Icon: ProfileIconNav },
  // { path: Paths.testPage, label: 'Test', Icon: TestIconNav },
];

export enum ImagePaths {
  trainerPhoto = 'files/image/trainer/photo',
  trainerCover = 'files/image/trainer/cover',
  courseCover = 'files/image/course/cover',
  clientAvatar = 'files/image/client/avatar',
  workoutImage = 'files/image/workout/image',
  socialImage = 'files/image/social/image',
  groupCover = 'files/image/course-group/cover',
  gradePhoto = 'files/image/grade/image',
  commentImage = 'files/image/comment/image',
  bannerImage = 'files/image/banner/image',
  bannedImage = 'files/ban/comment/ban',
  storyCover = 'files/image/story/cover',
  storyComponent = 'files/image/component/files',
  notificationImage = 'files/image/notification/push',
}

export enum FilesCoverPath {
  workout = 'files/image/workout/files',
  course = 'files/image/course/files',
}

export enum BaseVideoCoverPath {
  path = 'files/link/course/files',
}

// export enum StoryVideoCoverPath {
//   path = 'files/link/story/files',
// }

export enum ApiVersion {
  baseService = 'api/v1',
  videoService = 'api/v1',
  analyticsService = 'api/v1',
  appService = 'api/v1',
  notificationsService = 'api/v1',
  commentsService = 'api/v1',
}

export enum Statuses {
  idle = 'idle',
  loading = 'loading',
  succeeded = 'succeeded',
  failed = 'failed',
}

export enum ResizeType {
  none = '0',
  resizeWidthTop = '1',
  resizeWidthCenter = '2',
  resizeWidthBottom = '3',
  resizeHeightLeft = '4',
  resizeHeightCenter = '5',
  resizeHeightRight = '6',
  resizeBothCenter = '7',
}

export enum ValuesOfConstants {
  licenseAgreement = 'license-agreement',
  userAgreement = 'user-agreement',
  privacyPolicy = 'privacy-policy',
  measureBreasts = 'how-to-measure-breasts',
  measureWaist = 'how-to-measure-waist',
  measureHips = 'how-to-measure-hips',
  onboardingLevel1 = 'onboarding-level-0',
  onboardingLevel2 = 'onboarding-level-1',
  onboardingLevel3 = 'onboarding-level-2',
  reasonsDeleteAccount = 'reasons-delete-account',
  advertisingMailings = 'advertising-mailings',
  processingPersonalData = 'processing-personal-data',
  caloriesText = 'calories-text',
  intensityText = 'intensity-text',
  bottomSheetPaymentProgramm = 'bottom-sheet-payment-programm',
  bottomSheetPaymentWorkout = 'bottom-sheet-payment-workout',
  ratingText = 'rating-text',
  isRecurrentPayment = 'is-recurrent-payment',
  telegramSupport = 'telegram-support',
  FAQ = 'FAQ',
  referralProgrammeRules = 'referral-programme-rules',
  telegramChannel = 'telegram-channel',
}

export enum TypesStatusesOrderPaid {
  paid = 1,
  unpaid = 2,
  failed = 3,
  canceled = 4,
}

export enum FilterNames {
  course_type = 'course_type',
  name = 'name',
  direction = 'direction',
  levels = 'levels',
  active_area = 'active_area',
  inventory = 'inventory',
  duration = 'duration',
  groups = 'groups',
  haveInventory = 'haveInventory',
}

export enum LevelsID {
  beginner = 1,
  middle = 2,
  high = 3,
}

export enum AnalyticsName {
  //1 итерация
  forgotPassword = 'click-btn-forgot password',
  registration = 'click-btn-registration',
  watchTrailer = 'click-btn-watch-trailer',
  course = 'click-course',
  courseFavorites = 'click-course-favorites',
  workoutFavorites = 'click-workout-favorites',
  trainerInfo = 'click-trainer-info',
  location = 'location',
  //2 итерация
  login = 'click-btn-login',
  acceptEverything = 'click-btn-accept_everything',
  userAgreement = 'click-link-user_agreement',
  privacyPolicy = 'click-link-privacy_policy',
  processingPersonalData = 'click-link-processing_personal_data',
  setUserAgreementsNext = 'click-btn-set_user_agreements_next',
  onboardingAboutSelfChooseFemale = 'user-choose-onboarding_female',
  onboardingAboutSelfChooseSetMale = 'user-choose-onboarding_male',
  registrationCompleted = 'open-screen-registration_completed',
  registrationCompletedNext = 'click-btn-registration_completed_next',
  onboardingAboutSelfNext = 'click-btn-onboarding_about_self_next',
  onboardingChooseTargetNext = 'click-btn-onboarding_choose_target_next',
  onboardingDefineLevelFinished = 'click-btn-onboarding_define_level_finished',
  onboardingDefineCreatingProggrams = 'onboarding_define_creating_proggrams',
  //3 итерация
  workoutCompleted = 'profile_active_training', // возможно заренеймить,хз
  clickSocial = 'click-social',
  profileAvatar = 'profile_avatar',
  profileNotification = 'setting_profile_notification',
  appEvaluation = 'app_evaluation',
  appEvaluationSkip = 'app_evaluation_skip',
  activeDay = 'active_day',
  currentWeight = 'current_weight',
  clearWeightAll = 'clear_weight_all',
  currentChest = 'current_chest',
  clearChestAll = 'clear_chest_all',
  currentHips = 'current_hips',
  clearHipsAll = 'clear_hips_all',
  currentWaist = 'current_waist',
  clearWaistAll = 'clear_waist_all',
  currentHeight = 'current_height',
  clearHeightAll = 'clear_height_all',
  currentBmi = 'current_bmi',
  subscriptionType = 'subscription_type',
  add_access = 'add_access',
  current_distance = 'current_distance',
  current_target_distance = 'current_target_distance',
  clear_distance_all = 'clear_distance_all',
}

export enum ErrorMessages {
  uploadAvatar = 'Размер загружаемой фотографии не должен превышать 5 мб',
  defaultTextError = 'Ошибка, не удалось выполнить действие!',
  offlineApplication = 'Нет соединения с интернетом',
  deleteCard = 'Ошибка при удалении карты. Попробуйте еще раз позднее',
  socialAuth = 'Ошибка авторизации, попробуйте ещё раз или воспользуйтесь другим видом авторизации',
  setTokens = 'Произошла ошибка выполнения нативной функции  window.setTokens',
  removeTokens = 'Произошла ошибка выполнения нативной функции  window.removeTokens',
  openPlayer = 'Произошла ошибка выполнения нативной функции  window.openPlayer',
  getBackUrl = 'Произошла ошибка выполнения нативной функции  window.getBackUrl',
  promocodeLimitExceeded = 'Промокод недействителен',
  promocodeExpired = 'Срок действия истек',
  promocodeNotFitAnotherConditions = 'Промокод не найден',
  futureDate = 'Нельзя выбрать будущую дату',
  getPlatformVersion = 'Ошибка выполнения функции getPlatformVersion, свяжитесь с командой разработки',
  refreshToken = 'Сессия истекла',
  loginConfirmation = 'Код проверки неверный',
  sendLogs = 'Сервер временно не доступен, повторите попытку позже',
  setMainBankCard = 'Ошибка при обновлении карты. Попробуйте еще раз позднее',
  bindingBankCard = 'Ошибка добавления карты',
  paymentTypeInvalid = 'Оплата недоступна',
  payment = 'Ошибка при оплате',
}

export enum TypesCardValues {
  mir = 'Mir',
  maestro = 'Maestro',
  visa = 'Visa',
  masterCard = 'MasterCard',
  robokassa = 'robokassa',
}

export enum OnboardingSteps {
  aboutSelf = 'about_self',
  setDateOfBirth = 'set_date_of_birth',
  setWeight = 'set_weight',
  setHeight = 'set_height',
  chooseTarget = 'choose_target',
  defineLevel = 'define_level',
  creatingProgram = 'creating_program',
}

export enum PushNotificationsTypes {
  sms = 'sms',
  email = 'email',
  push = 'push',
}

export enum TypeSizeDashboard {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export const ARRAY_OF_MONTHS = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
] as const;

export enum Environment {
  development = 'development',
  production = 'production',
}

export enum ActivityNavAction {
  create = 'create',
  edit = 'edit',
}

export const ACTIVITY_DATE_FORMATE = 'dd.MM.yyyy';

export enum SaveScrollPathnames {
  courses = 'courses',
  profile = 'profile',
  courseVideos = 'courseVideos',
  singleWorkoutsGroups = 'singleWorkoutsGroups',
  main = 'main',
  singleWorkouts = 'single_workouts',
  collectionsDetails = 'collections_details',
  collections = 'collections',
  storySizes = 'storySizes',
  storyWeight = 'storyWeight',
  paymentHistory = 'paymentHistory',
  stepmeter = 'stepmeter',
  sliderCards = 'sliderCards',
  favorites = 'favorites',
}

export const HISTORY_DATE_FORMATE = 'yyyy-MM-dd';

export enum Action {
  add = 'add',
  create = 'create',
  remove = 'remove',
  edit = 'edit',
}

export const AnalyticsNameByParametersID = {
  [PARAMETERS_TYPES.height.id]: AnalyticsName.currentHeight,
  [PARAMETERS_TYPES.weight.id]: AnalyticsName.currentWeight,
  [PARAMETERS_TYPES.chest.id]: AnalyticsName.currentChest,
  [PARAMETERS_TYPES.hips.id]: AnalyticsName.currentHips,
  [PARAMETERS_TYPES.waist.id]: AnalyticsName.currentWaist,
} as const;

export const AnalyticsNameByParametersIDClearAll = {
  [PARAMETERS_TYPES.height.id]: AnalyticsName.clearHeightAll,
  [PARAMETERS_TYPES.weight.id]: AnalyticsName.clearWeightAll,
  [PARAMETERS_TYPES.chest.id]: AnalyticsName.clearChestAll,
  [PARAMETERS_TYPES.hips.id]: AnalyticsName.clearHipsAll,
  [PARAMETERS_TYPES.waist.id]: AnalyticsName.clearWaistAll,
} as const;

export enum TypesSort {
  // default = 'default',
  popular = 'popular',
  novelty = 'novelty',
  durationMin = 'durationMin',
  durationMax = 'durationMax',
  popularGroup = 'popularGroup',
}

export const SORT_NAMES = {
  [TypesSort.popular]: 'популярные',
  [TypesSort.novelty]: 'новые',
  [TypesSort.durationMin]: 'короткие',
  [TypesSort.durationMax]: 'длинные',
  [TypesSort.popularGroup]: 'популярные',
} as const;

export enum CollectionTypes {
  new = 'new',
  popular = 'popular',
  collection = 'collection',
}

export enum NotificationParamsNames {
  image = 'image',
  redirect = 'redirect',
  type = 'type',
}

export enum RenderSchemesNames {
  duration = 'duration',
  schemeWorkout = 'scheme-workout',
}

export const promocodeErrorsByIndex: Record<string, number> = {
  'Выбранное значение для promocode некорректно.': 1,
  'Promotions not started for this promocode': 2,
  'Promocode usage limit exceeded': 3,
  'Promotions expired for this promocode': 4,
  'Promocode is not valid for this product': 5,
  'Promocode is not valid for this platform': 6,
};

export const PRODUCT_CARD_IMAGE_SIZES = {
  L: { width: 700, height: 800 },
  M: { width: 400, height: 400 },
  S: { width: 200, height: 200 },
} as const;
