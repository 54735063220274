import React, { FC, useCallback } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import s from './styles.module.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { ImageCover } from '../..';
import { useEffectOnce } from 'usehooks-ts';
import { getPromoBannersThunk } from '../../../redux/promoBanners/thunks';
import { IBannerElemAutoplay } from '../../../api/types';
import { isInternalPath } from '../../../utils/isInternalPath';

export const TEST_ID = 'PromoBanner';

interface IProps {
  autoplay?: IBannerElemAutoplay;
}

const PromoBanner: FC<IProps> = ({ autoplay }) => {
  const {
    promoBannersStore: { banners },
  } = useAppSelector((state) => state);

  const dispatch = useAppDispatch();

  useEffectOnce(() => {
    dispatch(getPromoBannersThunk());
  });

  const handleOpenURL = useCallback(
    (link: string) => () => {
      if (!isInternalPath(link)) {
        window.openUrl(link);
      }
    },
    []
  );

  return (
    <div>
      <Swiper
        autoplay={autoplay}
        slidesPerView={1}
        loop={banners.length > 1}
        spaceBetween={12}
        modules={[Autoplay]}
        className={s.promoBanner}
        style={{ padding: '0 12px' }}
        resistanceRatio={0}
      >
        {banners.map(({ cover, link }, index) => (
          <SwiperSlide key={`${cover}-${link}-${index}`}>
            <Stack flexShrink={0} className={s.bannerItem} onClick={handleOpenURL(link)}>
              {isInternalPath(link) && (
                <Link to={link.replace('#', '')} className={s.content}>
                  <ImageCover src={cover} maxZIndexPreloader />
                </Link>
              )}

              {!isInternalPath(link) && <ImageCover src={cover} maxZIndexPreloader />}
            </Stack>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default PromoBanner;
