import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getIsNeedOnboarding, Paths } from '../utils';
import useAuthenticated from './useAuthenticated';
import { useUser } from './useUser';

const { main, onboarding } = Paths;

const useAuthRedirect = () => {
  const { isAuthenticated } = useAuthenticated();

  const { user } = useUser();

  const { state } = useLocation();

  const navigate = useNavigate();

  const isNeedOnboarding = getIsNeedOnboarding(user);

  const redirectFrom = state?.from ? state.from : null;

  useEffect(() => {
    if (isAuthenticated && !isNeedOnboarding && redirectFrom) {
      navigate(redirectFrom);
    } else if (isAuthenticated && isNeedOnboarding) {
      navigate(onboarding);
    } else if (isAuthenticated) {
      navigate(main);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useAuthRedirect;
