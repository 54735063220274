import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ISingleWorkoutsGroups {
  isSearch: boolean;
}

const initialState: ISingleWorkoutsGroups = { isSearch: false };

export const singleWorkoutsGroupsSlice = createSlice({
  name: 'singleWorkoutsGroups',
  initialState,
  reducers: {
    setIsSearch(state, { payload }: PayloadAction<boolean>) {
      state.isSearch = payload;
    },
  },
});

export const { setIsSearch } = singleWorkoutsGroupsSlice.actions;

export default singleWorkoutsGroupsSlice.reducer;
