export enum WorkoutCardSize {
  L = 'L',
  M = 'M',
  S = 'S',
}

export const unit = {
  '1': '',
  '2': 'мин',
  '3': 'мин',
  '4': 'мин',
} as const;
