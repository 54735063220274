import { setImage } from '../redux/images/slice';
import { store } from '../redux/store';

const imageObserver = new IntersectionObserver(
  (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const target = entry.target as HTMLImageElement;

        if (target.dataset.src && target.dataset.nameforstore) {
          const image = new Image();
          image.src = target.dataset.src;
          image.onload = () => {
            store.dispatch(setImage({ name: target.dataset.nameforstore ?? '', imageUrl: target.dataset.src ?? '' }));
          };
        }

        observer.unobserve(entry.target);
      }
    });
  },
  {
    rootMargin: '50px 0px 0px',
  }
);

export const observeImage = (el: HTMLImageElement) => {
  return imageObserver.observe(el);
};
