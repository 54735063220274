import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyReferralFriends = lazy(() => import('./ReferralFriends'));

export const ReferralFriends = () => (
  <Suspense fallback={<Loading />}>
    <LazyReferralFriends />
  </Suspense>
);
