import { useNavigate } from 'react-router-dom';
import { KeyboardArrowLeftIcon, NamesOfColors } from 'front-package-ui-kit';

import { Paths } from '../../../utils';
import IconButtonUI from '../../atoms/IconButtonUI';

export const TEST_ID = 'go-back-button';

const { main } = Paths;

export interface GoBackButtonProps {
  white?: boolean;
  onClick?: () => void;
}

const GoBackButton = ({ white, onClick }: GoBackButtonProps) => {
  const navigate = useNavigate();

  const onClickHandler = () => {
    if (onClick) return onClick();

    const isHaveRouterHistory = window.history.length > 1;

    if (isHaveRouterHistory) {
      navigate(-1);
    } else {
      navigate(main);
    }
  };

  return (
    <IconButtonUI size={36} type="button" data-testid={TEST_ID} onClick={onClickHandler}>
      <KeyboardArrowLeftIcon color={white ? NamesOfColors.white100 : NamesOfColors.grey100} size={24} />
    </IconButtonUI>
  );
};

export default GoBackButton;
