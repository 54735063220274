import { Environment, OnboardingSteps, noticeCreator } from '.';
import api from '../api';
import { IUser } from '../api/types';
import { createServiceNotice } from '../redux/notifications/slice';
import { store } from '../redux/store';
import { setUser } from '../redux/user/slice';
import { getTextError } from '../redux/utils';
import { updateUserVersion } from './updateUserVersion';

const NUMBER_OF_ONBOARDING_STEPS = Object.values(OnboardingSteps).length;

export const getUserInfo = async () => {
  try {
    const platform = await window.getPlatform();

    const user = (await api.getMyInfo(platform)).data.data;

    store.dispatch(setUser(user));

    updateUserVersion(user);

    return user;
  } catch (error) {
    const textError = getTextError(error);

    store.dispatch(
      createServiceNotice({
        notice: noticeCreator(textError, 'error'),
        otherInfo: { error, pathname: 'onboardingHelpers/getUserInfo', forEnvironment: Environment.development },
      })
    );
  }
};

export const getIsNeedOnboarding = (user?: IUser | null): boolean | null => {
  if (!user) return null;

  if (user.onboard_step === undefined) return true;

  if (user.onboard_step < NUMBER_OF_ONBOARDING_STEPS) return true;

  return false;
};
